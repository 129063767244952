<template>
    <div>
        <div class="gl_title">查车价定价</div>
        <el-divider></el-divider>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="基础服务费" prop="name">
                <el-input class="gl_input" v-model="ruleForm.name">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>

            <el-form-item label="厂商指导价" prop="name">
                <el-input class="gl_input" v-model="ruleForm.name">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>

            <el-form-item label="近期成交价" prop="name">
                <el-input class="gl_input" v-model="ruleForm.name">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>


            <el-form-item></el-form-item>

            <el-form-item label="有效期" prop="name">
                <el-input class="gl_input" v-model="ruleForm.name">
                    <template slot="append">元</template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
export default {
    data() {
        return {
            ruleForm: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    created() {

    },
    mouted() {

    },
    methods: {

    },
}
</script>
<style scoped>
.gl_title {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
}

.gl_input {
    width: 320px;
}
</style>